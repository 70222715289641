var APIURL = process.env.MIX_APP_URL + '/api'

var forms = {}
var steps = {}
var payments = {}
var datas = {}
var urls = {}
var urlParams = {}
var currentStep = {}
var maskObjs = {}
var stripe = null
var elements = null
var donorInfo = {}
var amazonjSON = {}
var amazonPay = false

nodeScriptReplace = (node) => {
    if (node?.tagName && node?.tagName?.toUpperCase() === 'SCRIPT') {
        node.parentNode.replaceChild(nodeScriptClone(node), node)
    } else {
        var i = -1,
            children = node.childNodes
        while (++i < children.length) {
            nodeScriptReplace(children[i])
        }
    }

    return node
}

nodeScriptClone = (node) => {
    var script = document.createElement('script')
    script.text = node.innerHTML

    var i = -1,
        attrs = node.attributes,
        attr
    while (++i < attrs.length) {
        script.setAttribute((attr = attrs[i]).name, attr.value)
    }
    return script
}

parse_query_string = (query) => {
    var query_string = {}

    if (!query) return query_string

    var decodedQuery = decodeURIComponent(query).replace(/\?/g, '&')
    var vars = decodedQuery.split('&')

    /* for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        var key = decodeURIComponent(pair[0])
        var value = decodeURIComponent(pair[1])

        if (!key || !value) continue

        // If first entry with this name
        if (typeof query_string[key] === 'undefined') {
            query_string[key] = decodeURIComponent(value)
            // If second entry with this name
        } else if (typeof query_string[key] === 'string') {
            var arr = [query_string[key], decodeURIComponent(value)]
            query_string[key] = arr
            // If third or later entry with this name
        } else {
            query_string[key].push(decodeURIComponent(value))
        }
    } */

    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        var key = pair[0]
        var value = pair[1]

        if (!key || !value) continue

        // If first entry with this name
        if (typeof query_string[key] === 'undefined') {
            query_string[key] = value
            // If second entry with this name
        } else if (typeof query_string[key] === 'string') {
            var arr = [query_string[key], value]
            query_string[key] = arr
            // If third or later entry with this name
        } else {
            query_string[key].push(value)
        }
    }

    return query_string
}

serialize = (obj, prefix) => {
    var str = [],
        p
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + '[' + p + ']' : p,
                v = obj[p]
            str.push(
                v !== null && typeof v === 'object'
                    ? serialize(v, k)
                    : encodeURIComponent(k) + '=' + encodeURIComponent(v)
            )
        }
    }
    return str.join('&')
}

initUrlParams = () => {
    var query = window.location.search.substring(1)
    urlParams = parse_query_string(query)
}

getDefaultsFormData = () => {
    if (!urlParams.def) return {}

    let dataDecoded = window.atob(urlParams.def)
    if (!dataDecoded) return {}

    return parse_query_string(dataDecoded || '')
}

emptyNull = (val) => {
    return val === null ||
        val === 'null' ||
        val === 'NULL' ||
        val === 'undefined' ||
        typeof val === 'undefined'
        ? ''
        : val
}

request = (relativeurl, method, params, callback, callbackError, hasFormData = false) => {
    var url = APIURL + relativeurl
    var xhr = new XMLHttpRequest()

    xhr.open(method.toUpperCase(), url, true)
    if (!hasFormData) xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8')
    xhr.setRequestHeader('Accept', 'application/json; charset=utf-8')

    xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return

        if (xhr.status >= 200 && xhr.status < 300) {
            var response = xhr.responseText

            try {
                response = JSON.parse(xhr.responseText)
            } catch (e) { }

            if (typeof callback == 'function') return callback(response)
            else return
        } else if (xhr.status >= 400 && xhr.status < 600) {
            var response = xhr.responseText

            try {
                response = JSON.parse(xhr.responseText)
            } catch (e) { }

            if (typeof callback == 'function') return callbackError(response)
            else return
        } else return
    }

    if (hasFormData) {
        var formDataParams = new FormData()
        for (var key in params) {
            if (typeof params[key] === 'object') {
                for (var key2 in params[key]) {
                    if (
                        typeof params[key][key2] === 'object' &&
                        params[key][key2] instanceof FileList
                    ) {
                        formDataParams.append(
                            key + '[' + key2 + ']',
                            emptyNull(params[key][key2][0])
                        )
                    } else if (
                        typeof params[key][key2] === 'object' ||
                        Array.isArray(params[key][key2])
                    ) {
                        formDataParams.append(
                            key + '[' + key2 + ']',
                            emptyNull(JSON.stringify(params[key][key2]))
                        )
                    } else {
                        formDataParams.append(key + '[' + key2 + ']', emptyNull(params[key][key2]))
                    }
                }
            } else if (Array.isArray(params[key])) {
                for (let i = 0; i < params[key].length; i++) {
                    if (typeof params[key][i] === 'object' || Array.isArray(params[key][i])) {
                        formDataParams.append(
                            key + '[' + i + ']',
                            emptyNull(JSON.stringify(params[key][i]))
                        )
                    } else {
                        formDataParams.append(key + '[' + i + ']', emptyNull(params[key][i]))
                    }
                }
            } else {
                formDataParams.append(key, emptyNull(params[key]))
            }
        }
        xhr.send(formDataParams)
    } else {
        xhr.send(JSON.stringify(params))
    }
}

getCookie = (cname) => {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

saveData = (refId, callback) => {
    if (!datas[refId]) {
        if (typeof callback == 'function') callback()
        else return false
    }

    let method = !!datas[refId].base.donor ? 'PATCH' : 'POST'

    let formData = datas[refId]
    if (method == 'PATCH') {
        method = 'POST'
        formData['_method'] = 'PATCH'
    }

    request(
        '/source/donor/token',
        method,
        formData,
        (response) => {
            if (typeof response.data.donor !== 'undefined')
                datas[refId].base.donor = response.data.donor

            if (typeof callback == 'function') callback(response)
        },
        (response) => {
            const url = response?.error_url ?? window.location.href
            const message =
                typeof response?.error === 'string'
                    ? response?.error
                    : JSON.stringify(response?.error)

            if (url && message) window.location.href = buildUrlWithParams(url, 'message', message)
            else if (url && !message) window.location.href = url
            else window.location.reload()

            return
        },
        true
    )
}

redirectSuccessUrl = (refId) => {
    request(
        '/source/return?request_id=' + datas[refId].base.request_id,
        'GET',
        {},
        (response) => {
            if (typeof response.data.success_url !== 'undefined')
                window.location.href = response.data.success_url
        },
        (response) => {
            const url = response?.error_url ?? window.location.href
            const message =
                typeof response?.error === 'string'
                    ? response?.error
                    : JSON.stringify(response?.error)
            if (url && message) window.location.href = buildUrlWithParams(url, 'message', message)
            else if (url && !message) window.location.href = url
            else window.location.reload()
            return
        }
    )
}

lastStepRequest = (refId) => {
    request(
        '/source/return?request_id=' + datas[refId].base.request_id,
        'GET',
        {},
        (response) => {
            steps[refId][currentStep[refId]].forEach((element) => {
                if (
                    !!element.event_type &&
                    element.event_type === 'iscrizione_newsletter_completata'
                ) {
                    newsLetter(refId)
                } else if (!!element.event_type && element.event_type === 'form_completato') {
                    completedForm(refId)
                }
            })
        },
        (response) => {
            //
        }
    )
}

validateEmail = (email) => {
    let re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}

validatePhoneNumber = (phone) => {
    if (phone.length < 3 || phone.length > 23) return false

    let re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
    return re.test(phone)
}

validatePattern = (pattern, text) => {
    let re = new RegExp(pattern)
    return re.test(text)
}

validateDate = (date) => {
    let re = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))*$/
    return re.test(date)
}

validateTime = (time) => {
    let re = /^((?:\d|[01]\d|2[0-3]):[0-5]\d)*$/
    return re.test(time)
}

convertDate = (date) => {
    if (!date) return null

    let dateValue = new Date(date)

    var day = dateValue.getDate()
    var month = dateValue.getMonth() + 1
    var year = dateValue.getFullYear()

    if (day < 10) day = '0' + day
    if (month < 10) month = '0' + month

    return [year, month, day].join('-')
}

convertTime = (time) => {
    return time.replace(/-/g, '0')
}

validateUrl = (url) => {
    var pattern = new RegExp(
        '^((ft|htt)ps?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
        'i'
    ) // fragment locator
    return !!pattern.test(url)
}

pad = (number) => {
    if (String(number).length < 2) {
        return (number < 10 ? '0' : '') + number
    } else {
        return number
    }
}

get18yearAgo = () => {
    let date = new Date()
    date.setFullYear(date.getFullYear() - 18)

    return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDay())
}

validateDate18Years = (value) => {
    let date = new Date()
    date.setFullYear(date.getFullYear() - 18)

    let dateValue = new Date(value)

    return dateValue <= date ? true : false
}

buildUrlWithParams = (url, name, value) => {
    if (!url) return null
    if (!name || !value) return url

    if (url.indexOf('?') == -1) return url + '?' + name + '=' + value
    else return url + '&' + name + '=' + value
}

stripeCheckout = (event) => {
    let refId = event.target.closest('.aism-uniform').id
    showLoader(refId)
    hasEvent(refId, 'carta di credito')

    if (!datas[refId]) return hideLoader(refId)

    request(
        '/payments/stripe/session',
        'POST',
        datas[refId],
        (response) => {
            if (!response.data || !response.data.key) return hideLoader(refId)

            let stripe = Stripe(response.data.key)

            stripe
                .redirectToCheckout({
                    sessionId: response.data.id,
                })
                .then((result) => {
                    if (!!result.error && !!result.error.message)
                        window.location.href = buildUrlWithParams(
                            response.data.error_url,
                            'message',
                            result.error.message
                        )
                    else if (!!result.error && !result.error.message)
                        window.location.href = response.data.error_url
                    else window.location.href = response.data.success_url
                })
        },
        (response) => {
            const url = response?.error_url ?? window.location.href
            const message =
                typeof response?.error === 'string'
                    ? response?.error
                    : JSON.stringify(response?.error)

            if (url && message) window.location.href = buildUrlWithParams(url, 'message', message)
            else if (url && !message) window.location.href = url
            else window.location.reload()

            return
        }
    )
}

paypalCheckout = (event) => {
    let refId = event.target.closest('.aism-uniform').id
    hasEvent(refId, 'paypal')

    showLoader(refId)

    if (!datas[refId]) return hideLoader(refId)

    request(
        '/payments/paypal/session',
        'POST',
        datas[refId],
        (response) => {
            window.location.href = response.data.url
        },
        (response) => {
            const url = response?.error_url ?? window.location.href
            const message =
                typeof response?.error === 'string'
                    ? response?.error
                    : JSON.stringify(response?.error)

            if (url && message) window.location.href = buildUrlWithParams(url, 'message', message)
            else if (url && !message) window.location.href = url
            else window.location.reload()

            return
        }
    )
}

satispayCheckout = (event) => {
    let refId = event.target.closest('.aism-uniform').id
    showLoader(refId)
    hasEvent(refId, 'satispay')

    if (!datas[refId]) return hideLoader(refId)

    request(
        '/payments/satispay/session',
        'POST',
        datas[refId],
        (response) => {
            window.location.href = response.data.url
        },
        (response) => {
            const url = response?.error_url ?? window.location.href
            const message =
                typeof response?.error === 'string'
                    ? response?.error
                    : JSON.stringify(response?.error)

            if (url && message) window.location.href = buildUrlWithParams(url, 'message', message)
            else if (url && !message) window.location.href = url
            else window.location.reload()

            return
        }
    )
}

amazonpayCheckout = (event) => {
    let refId = event.target.closest('.aism-uniform').id
    hasEvent(refId, 'amazonpay')

    showLoader(refId)
    if (!datas[refId]) return hideLoader(refId)
    //console.log('AMAZONPAY')
}

sepaCheckout = (event) => {
    let refId = event.target.closest('.aism-uniform').id
    showLoader(refId)
    hasEvent(refId, 'bonifico sepa')

    if (!datas[refId]) return hideLoader(refId)

    datas[refId].payment['client_secret'] = null
    datas[refId].payment['accountholder-name'] = null
    datas[refId].payment['accountholder-email'] = null
    urls = {}

    request_url =
        datas[refId].donation.recurrent > 0
            ? '/payments/stripe/session/sepa/regular'
            : '/payments/stripe/session/sepa/oneoff'

    request(
        request_url,
        'POST',
        datas[refId],
        (response) => {
            if (!response.data || !response.data.key || !response.data.client_secret)
                return hideLoader(refId)

            currentStep[refId] = currentStep[refId] + 1

            if (!!response.data.success_url) urls.success_url = response.data.success_url
            if (!!response.data.error_url) urls.error_url = response.data.error_url

            if (!!response.data.email)
                datas[refId].payment['accountholder-email'] = response.data.email
            if (!!response.data.full_name)
                datas[refId].payment['accountholder-name'] = response.data.full_name

            initStepStripeElementsIBAN(
                refId,
                datas[refId].payment['accountholder-email'],
                datas[refId].payment['accountholder-name']
            )

            stripe = Stripe(response.data.key)
            elements = stripe.elements()

            datas[refId].payment['client_secret'] = response.data.client_secret

            let iban = elements.create('iban', {
                style: {
                    base: {
                        fontSize: '18px',
                        color: 'black',
                        iconColor: '#999',
                    },
                    invalid: {
                        color: '#c8102e',
                        iconColor: '#c8102e',
                    },
                },
                classes: {
                    base: 'aismform-control',
                    invalid: 'aismis-invalid',
                },
                supportedCountries: ['SEPA'],
                placeholderCountry: 'IT',
                iconStyle: 'solid',
                hideIcon: true,
            })

            iban.mount('#iban-aismelement')

            iban.on('change', (event) => {
                let displayError = document.getElementById('iban-error-aismmessage')
                let displayMsg = document.getElementById('iban-help-aismmessage')

                if (!!event.error && !!event.error.message) {
                    displayError.innerHTML = event.error.message
                    displayMsg.innerHTML = ''
                } else if (!event.error) {
                    displayError.innerHTML = ''

                    let info = []

                    if (!!event.country) info.push('<b>Nazione:</b> ' + event.country)
                    if (!!event.bankName) info.push('<b>Banca:</b> ' + event.bankName)

                    displayMsg.innerHTML = !!info && !!info.length ? info.join(', ') : ''
                } else {
                    displayError.innerHTML = ''
                    displayMsg.innerHTML = ''
                }

                if (!!event.complete) document.getElementById('pay-sepa').disabled = false
                else if (!event.complete) document.getElementById('pay-sepa').disabled = true
            })
        },
        (response) => {
            const url = response?.error_url ?? window.location.href
            const message =
                typeof response?.error === 'string'
                    ? response?.error
                    : JSON.stringify(response?.error)

            if (url && message) window.location.href = buildUrlWithParams(url, 'message', message)
            else if (url && !message) window.location.href = url
            else window.location.reload()

            return
        }
    )
}

sepaSubmit = (event) => {
    if (!!document.getElementById('pay-sepa').disabled) return false

    let refId = event.target.closest('.aism-uniform').id
    showLoader(refId)

    if (
        !datas[refId] ||
        !datas[refId].payment ||
        !datas[refId].payment['client_secret'] ||
        !validateStep(refId)
    )
        return hideLoader(refId)

    let iban = elements.getElement('iban')
    if (!iban) return hideLoader(refId)

    if (datas[refId].donation.recurrent > 0) {
        stripe
            .confirmSepaDebitSetup(datas[refId].payment['client_secret'], {
                payment_method: {
                    sepa_debit: iban,
                    billing_details: {
                        name: datas[refId].payment['accountholder-name'],
                        email: datas[refId].payment['accountholder-email'],
                    },
                },
            })
            .then(function (result) {
                let dataReq = {
                    request_id: null,
                    payment_intent: null,
                    payment_method: null,
                    error: false,
                }

                if (!!datas[refId] && !!datas[refId].base && !!datas[refId].base.request_id)
                    dataReq.request_id = datas[refId].base.request_id

                if (!!result.setupIntent) {
                    if (!!result.setupIntent.id) dataReq.setup_intent = result.setupIntent.id

                    if (!!result.setupIntent.payment_method)
                        dataReq.payment_method = result.setupIntent.payment_method
                }

                if (!!result.error) dataReq.payment_method = true

                request(
                    '/payments/stripe/session/sepa/regular/confirm',
                    'POST',
                    dataReq,
                    (response) => {
                        if (!!result.error && !!result.error.message)
                            window.location.href = buildUrlWithParams(
                                urls.error_url,
                                'message',
                                result.error.message
                            )
                        else if (!!result.error && !result.error.message)
                            window.location.href = urls.error_url
                        else window.location.href = urls.success_url

                        delete datas[refId].payment['client_secret']
                        delete datas[refId].payment['accountholder-name']
                        delete datas[refId].payment['accountholder-email']
                        urls = {}
                    },
                    (response) => {
                        const url = response?.error_url ?? window.location.href
                        const message =
                            typeof response?.error === 'string'
                                ? response?.error
                                : JSON.stringify(response?.error)
                        if (url && message)
                            window.location.href = buildUrlWithParams(url, 'message', message)
                        else if (url && !message) window.location.href = url
                        else window.location.reload()
                        return
                    }
                )
            })
    } else {
        stripe
            .confirmSepaDebitPayment(datas[refId].payment['client_secret'], {
                payment_method: {
                    sepa_debit: iban,
                    billing_details: {
                        name: datas[refId].payment['accountholder-name'],
                        email: datas[refId].payment['accountholder-email'],
                    },
                },
            })
            .then(function (result) {
                let dataReq = {
                    request_id: null,
                    payment_intent: null,
                    payment_method: null,
                    error: false,
                }

                if (!!datas[refId] && !!datas[refId].base && !!datas[refId].base.request_id)
                    dataReq.request_id = datas[refId].base.request_id

                if (!!result.paymentIntent) {
                    if (!!result.paymentIntent.id) dataReq.payment_intent = result.paymentIntent.id

                    if (!!result.paymentIntent.payment_method)
                        dataReq.payment_method = result.paymentIntent.payment_method
                }

                if (!!result.error) dataReq.payment_method = true

                request(
                    '/payments/stripe/session/sepa/oneoff/confirm',
                    'POST',
                    dataReq,
                    (response) => {
                        if (!!result.error && !!result.error.message)
                            window.location.href = buildUrlWithParams(
                                urls.error_url,
                                'message',
                                result.error.message
                            )
                        else if (!!result.error && !result.error.message)
                            window.location.href = urls.error_url
                        else window.location.href = urls.success_url

                        delete datas[refId].payment['client_secret']
                        delete datas[refId].payment['accountholder-name']
                        delete datas[refId].payment['accountholder-email']
                        urls = {}
                    },
                    (response) => {
                        const url = response?.error_url ?? window.location.href
                        const message =
                            typeof response?.error === 'string'
                                ? response?.error
                                : JSON.stringify(response?.error)
                        if (url && message)
                            window.location.href = buildUrlWithParams(url, 'message', message)
                        else if (url && !message) window.location.href = url
                        else window.location.reload()
                        return
                    }
                )
            })
    }
}

initStepStripeElementsIBAN = (refId, email = null, full_name = null) => {
    let html = ''
    let stepData = steps[refId][currentStep[refId] - 1][0]
    console.log('stepData', stepData)

    if (!!email) email = ' value="' + email + '"'
    if (!!full_name) full_name = ' value="' + full_name + '"'

    html += '<div class="aism-loader"><span class="step-loader"></span></div>'

    if (!!datas[refId].donation && !!datas[refId].donation.importo) {
        if (datas[refId].donation.recurrent == '2') donationTypeString = 'annuale'
        else if (datas[refId].donation.recurrent == '1') donationTypeString = 'mensile'
        else donationTypeString = 'singola'
        // let type = !stepData.formDonazioni ? 'Iscrizione' : 'Donazione'
        let type = ''
        if (stepData.formDonazioni === 1) {
            type = 'la Donazione'
        } else if (stepData.formDonazioni === 0) {
            type = "l'Iscrizione"
        } else {
            type = 'Nessuna attività selezionata'
        }
        if (!stepData.formDonazioni) {
            if (datas[refId].donation.recurrent == '2') donationTypeString = 'annuale'
            else if (datas[refId].donation.recurrent == '1') donationTypeString = 'mensile'
            else donationTypeString = 'singola'
        }
        html += '<div class="aism-donationdetails">'
        html +=
            '<span class="aism-donationdetails-title">' +
            type +
            ' ' +
            donationTypeString +
            ' di <span class="aism-color-orange">' +
            datas[refId].donation.importo +
            ' €</span></span>'
        html += !!datas[refId].donation._description
            ? '<span class="aism-donationdetails-body">' +
            datas[refId].donation._description +
            '</span>'
            : ''
        html += '</div>'
    }
    let type = ''
    if (stepData.formDonazioni === 1) {
        type = 'la Donazione'
    } else if (stepData.formDonazioni === 0) {
        type = "l'Iscrizione"
    } else {
        type = 'Nessuna attività selezionata'
    }
    html += "<span class='step-title'>Effettua " + type + '</span>'

    html += '<div class="step-content">'

    html += '<span class="step-subtitle">Bonifico Bancario</span>'

    html += '<div class="aismform-group aismname-accountholder-name">'
    html += '<label class="aismLabel" for="accountholder-name">Titolare del Conto</label>'
    html +=
        '<input type="text" class="aismform-control" id="accountholder-name" name="accountholder-name" title="Titolare del Conto" ' +
        full_name +
        ' required />'
    html += '<div class="aisminvalid-feedback">Il campo è obbligatorio</div>'
    html += '</div>'

    html += '<div class="aismform-group aismname-accountholder-email">'
    html += '<label class="aismLabel" for="accountholder-email">Email</label>'
    html +=
        '<input type="email" class="aismform-control" id="accountholder-email" name="accountholder-email" title="Email" ' +
        email +
        ' required />'
    html += '<div class="aisminvalid-feedback">Il campo è obbligatorio</div>'
    html += '</div>'

    html += '<div class="aismform-group aismname-iban-aismelement">'
    html += '<label class="aismLabel" for="iban-aismelement">IBAN</label>'
    html += '<div id="iban-aismelement"></div>'
    html += '<small id="iban-help-aismmessage" class="aismform-text"></small>'
    html += '<div id="iban-error-aismmessage" class="aisminvalid-feedback" role="alert"></div>'
    html += '</div>'

    html +=
        '<div id="aismmandate-acceptance">Fornendo il tuo IBAN e confermando questo pagamento, autorizzi Associazione Italiana Sclerosi Multipla e Stripe, nostro fornitore di servizi di pagamento, a inviare istruzioni alla tua banca per addebitare la quota da te indicata sul tuo conto corrente in conformità alle norme di incasso del servizio SDD/SEPA.</div>'

    html += '</div>'

    html += '<div class="aismbuttons">'
    html +=
        '<button type="button" id="pay-sepa" class="aismbtn aismbtn-pay" disabled>' +
        (!stepData.formDonazioni ? 'Iscriviti' : 'Dona') +
        '</button>'
    html += '<button type="button" class="aismbtn aismbtn-back">Torna Indietro</button>'
    html += '</div>'

    html += '<p class="secure-text">'
    html += '<span class="secure"></span>'

    html +=
        'Pagamenti sicuri, grazie a Stripe e PayPal: protezione dei dati a 256bit, per la massima protezione nelle transazioni online.'
    /*html +=
        'Pagamenti sicuri, grazie a Stripe, PayPal, Satispay e Amazon Pay: protezione dei dati a 256bit, per la massima protezione nelle transazioni online.'*/
    html += '</p>'

    forms[refId].innerHTML = html

    hideLoader(refId)

    initListeners(refId)
}

updateElementValue = (event) => {
    let element = event.target
    let refId = element.closest('.aism-uniform').id
    let selectorName = false
    let elementValue = element.value
    let refForm = 'form'

    if (!!element.dataset.form) refForm = element.dataset.form

    if (!!element.name && !!maskObjs[element.name]) {
        maskObjs[element.name].updateValue()
        elementValue = maskObjs[element.name].typedValue
    }

    switch (element.tagName.toUpperCase()) {
        case 'INPUT':
            if (!!element.dataset.type)
                selectorName =
                    element.tagName.toUpperCase() + '.' + element.dataset.type.toUpperCase()
            else selectorName = element.tagName.toUpperCase() + '.' + element.type.toUpperCase()
            break
        default:
            selectorName = element.tagName.toUpperCase()
    }

    switch (selectorName) {
        case 'INPUT.FILE':
            if (!!element.required && !elementValue) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il campo è obbligatorio'
                })
            } else {
                element.classList.remove('aismis-invalid')
            }
            datas[refId][refForm][element.name] = element.files
            break
        case 'INPUT.NUMBER':
            elementValue = parseInt(elementValue)

            if (
                (!!elementValue || elementValue === 0) &&
                element.step % 1 == 0 &&
                elementValue % 1 != 0
            ) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il valore deve essere intero'
                })
            } else if (
                (!!elementValue || elementValue === 0) &&
                !!element.min &&
                parseInt(element.min) > parseInt(elementValue)
            ) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il valore minimo è ' + parseInt(element.min)
                })
            } else if (
                (!!elementValue || elementValue === 0) &&
                !!element.max &&
                parseInt(element.max) < parseInt(elementValue)
            ) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il valore massimo è ' + parseInt(element.max)
                })
            } else if (
                (elementValue === false ||
                    elementValue == '' ||
                    elementValue == null ||
                    elementValue.replace(/\s+/g, '') == '') &&
                !!element.required
            ) {
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il campo è obbligatorio'
                })
            } else {
                element.classList.remove('aismis-invalid')
            }

            datas[refId][refForm][element.name] = parseInt(elementValue)
            break
        case 'INPUT.EMAIL':
            if (!validateEmail(elementValue) && !!elementValue) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il testo inserito non è un indirizzo email'
                })
            } else if (
                !!element.required &&
                (!elementValue || elementValue.replace(/\s+/g, '') == '')
            ) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il campo è obbligatorio'
                })
            } else {
                element.classList.remove('aismis-invalid')
            }

            datas[refId][refForm][element.name] = elementValue
            break
        case 'INPUT.DATE':
            elementValue = convertDate(elementValue)

            if (!validateDate(elementValue) && !!elementValue) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il testo inserito non è una data valida (DD/MM/AAAA)'
                })
            } else if (
                element.name.toUpperCase().indexOf('NASCITA') != -1 &&
                !validateDate18Years(elementValue) &&
                !!elementValue
            ) {
                element.classList.add('aismis-invalid')

                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'La data di nascita non è di una persona maggiorenne'
                })
            } else if (
                !!element.required &&
                (!elementValue || elementValue.replace(/\s+/g, '') == '')
            ) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il campo è obbligatorio'
                })
            } else {
                element.classList.remove('aismis-invalid')
            }

            datas[refId][refForm][element.name] = elementValue
            break
        case 'INPUT.TEL':
            if (!validatePhoneNumber(elementValue) && !!elementValue) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il testo inserito non è un numero di telefono valido'
                })
            } else if (
                !!element.required &&
                (!elementValue || elementValue.replace(/\s+/g, '') == '')
            ) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il campo è obbligatorio'
                })
            } else {
                element.classList.remove('aismis-invalid')
            }

            datas[refId][refForm][element.name] = elementValue
            break
        case 'INPUT.URL':
            if (!validateUrl(elementValue) && !!elementValue) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il testo inserito non è uno URL valido'
                })
            } else if (
                !!element.required &&
                (!elementValue || elementValue.replace(/\s+/g, '') == '')
            ) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il campo è obbligatorio'
                })
            } else {
                element.classList.remove('aismis-invalid')
            }

            datas[refId][refForm][element.name] = elementValue
            break
        case 'INPUT.TIME':
            elementValue = convertTime(elementValue)

            if (!validateTime(elementValue) && !!elementValue) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il testo inserito non è un orario valido (HH:MM)'
                })
            } else if (
                !!element.required &&
                (!elementValue || elementValue.replace(/\s+/g, '') == '')
            ) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il campo è obbligatorio'
                })
            } else {
                element.classList.remove('aismis-invalid')
            }

            datas[refId][refForm][element.name] = elementValue
            break
        case 'INPUT.CHECKBOX':
            var checkedArr = []

            forms[refId].querySelectorAll('[name="' + element.name + '"]').forEach((element2) => {
                if (!!element2.checked) {
                    checkedArr.push(element2.value.toString())
                }
            })

            forms[refId].querySelectorAll('[name="' + element.name + '"]').forEach((element2) => {
                if (!!element2.required) {
                    if ((!checkedArr && checkedArr != 0) || !checkedArr.length) {
                        element2.classList.add('aismis-invalid')
                    } else {
                        element2.classList.remove('aismis-invalid')
                    }
                }
            })

            datas[refId][refForm][element.name] = checkedArr
            break
        case 'INPUT.RADIO':
            var checked = null

            forms[refId].querySelectorAll('[name="' + element.name + '"]').forEach((element2) => {
                if (!!element2.checked) {
                    checked = element2.value
                }
            })

            forms[refId].querySelectorAll('[name="' + element.name + '"]').forEach((element2) => {
                if (!!element2.required) {
                    if (!checked && checked != 0) {
                        element2.classList.add('aismis-invalid')
                    } else {
                        element2.classList.remove('aismis-invalid')
                    }
                }
            })

            datas[refId][refForm][element.name] = checked
            break
        default:
            if (
                !!element.pattern &&
                !validatePattern(element.pattern, elementValue) &&
                !!elementValue
            ) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il testo inserito non è un formato valido'
                })
            } else if (
                !!element.required &&
                (!elementValue || elementValue.replace(/\s+/g, '') == '')
            ) {
                element.classList.add('aismis-invalid')
                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il campo è obbligatorio'
                })
            } else {
                element.classList.remove('aismis-invalid')
            }

            datas[refId][refForm][element.name] = elementValue
    }
}

changeRecurrent = (event) => {
    let element = event.target
    let refId = element.closest('.aism-uniform').id

    element.parentNode.querySelectorAll('.aismbtn-active').forEach((element2) => {
        element2.classList.remove('aismbtn-active')
    })

    element.classList.add('aismbtn-active')
    datas[refId].donation.recurrent = parseInt(element.dataset.value)

    initCustomAmount(refId)
    initAmount(refId, true)
}

initAmount = (refId, reset) => {
    let donation = null

    if (!!steps[refId][currentStep[refId]]) {
        steps[refId][currentStep[refId]].forEach((element) => {
            if (!!element.donation) donation = element.donation
        })
    }

    console.log('donation', datas[refId].donation);

    if (!!donation && !!donation.amount && datas[refId].donation.recurrent != null) {
        var html = ''
        let buttonPresent = false

        donation.amount.forEach((element) => {
            if (element.type.indexOf(parseInt(datas[refId].donation.recurrent)) == -1) return

            if (!!reset) {
                var selected = !!element.default ? 'aismbtn-active' : ''
                datas[refId].donation.importo = !!element.default
                    ? element.value
                    : datas[refId].donation.importo
            } else {
                var selected =
                    element.value == datas[refId].donation.importo ? 'aismbtn-active' : ''
            }

            if (!!element.show_description_in_button) {
                html +=
                    '<button type="button" class="aismbtn aismbtn-amount ' +
                    selected +
                    '" data-value="' +
                    element.value +
                    '">' +
                    '<div>' +
                    '<span class="aismbtn-amount-value">' + element.value +
                    ' €' +
                    (parseInt(datas[refId].donation.recurrent) == 1
                        ? '<span class="aismsmall">/mese</span>'
                        : '') +
                    (parseInt(datas[refId].donation.recurrent) == 2
                        ? '<span class="aismsmall">/anno</span>'
                        : '') +
                    '</span>' +
                    '<span class="aismbtn-amount-description">' + element.description + '</span>' +
                    '</div>' +
                    '</button>'
            } else {
                html +=
                    '<button type="button" class="aismbtn aismbtn-amount ' +
                    selected +
                    '" data-value="' +
                    element.value +
                    '">' +
                    element.value +
                    ' €' +
                    (parseInt(datas[refId].donation.recurrent) == 1
                        ? '<span class="aismsmall">/mese</span>'
                        : '') +
                    (parseInt(datas[refId].donation.recurrent) == 2
                        ? '<span class="aismsmall">/anno</span>'
                        : '') +
                    '</button>'
            }


            if (!buttonPresent) buttonPresent = true
        })

        forms[refId].querySelectorAll('.donationAmount')[0].innerHTML = html

        forms[refId].querySelectorAll('.aismbtn-amount').forEach((element) => {
            element.addEventListener('click', changeAmount)
        })

        if (!!html) {
            forms[refId].querySelector('.aismname-customamount-separator').style.display = 'initial'
        } else {
            forms[refId].querySelector('.aismname-customamount-separator').style.display = 'none'
        }

        forms[refId].querySelectorAll('.customAmunt').forEach((element) => {
            if (!buttonPresent) element.required = true
            else element.required = false
            element.value = ''

            if (!!donation.customAmount.range[datas[refId].donation.recurrent].min)
                element.min = donation.customAmount.range[datas[refId].donation.recurrent].min

            if (!!donation.customAmount.range[datas[refId].donation.recurrent].max)
                element.max = donation.customAmount.range[datas[refId].donation.recurrent].max
        })

        if (
            !forms[refId].querySelectorAll('.aismbtn-amount.aismbtn-active').length &&
            !!datas[refId].donation.importo
        ) {
            forms[refId].querySelectorAll('.customAmunt').forEach((element) => {
                element.value = datas[refId].donation.importo
                if (!!donation.customAmount.range[datas[refId].donation.recurrent].min)
                    element.min = donation.customAmount.range[datas[refId].donation.recurrent].min

                if (!!donation.customAmount.range[datas[refId].donation.recurrent].max)
                    element.max = donation.customAmount.range[datas[refId].donation.recurrent].max
            })
        }

        donationAmountInfo(refId)
    }
}

initCustomAmount = (refId) => {
    var html = ''

    html += '<div class="aisminput-group amount-other">'

    html +=
        '<input type="number" step="1" class="aismform-control customAmunt" placeholder="Scegli tu l\'importo">'

    if (parseInt(datas[refId].donation.recurrent) == 0)
        html += '<div class="aisminput-group-append">€</div>'

    if (parseInt(datas[refId].donation.recurrent) == 1)
        html += '<div class="aisminput-group-append">€/mese</div>'

    if (parseInt(datas[refId].donation.recurrent) == 2)
        html += '<div class="aisminput-group-append">€/anno</div>'

    html += '<div class="aisminvalid-feedback"></div>'
    html += '</div>'

    forms[refId].querySelectorAll('.aismname-customamount').forEach((element) => {
        element.innerHTML = html
    })

    forms[refId].querySelectorAll('.customAmunt').forEach((element) => {
        element.addEventListener('input', changeCustomAmount)
        element.addEventListener('change', customAmountButton)
    })
}

changeAmount = (event) => {
    let element = event.target
    let buttonElement = element.closest('.aismbtn-amount')
    let refId = element.closest('.aism-uniform').id

    buttonElement.parentNode.querySelectorAll('.aismbtn-active').forEach((element2) => {
        element2.classList.remove('aismbtn-active')
    })

    buttonElement.classList.add('aismbtn-active')
    datas[refId].donation.importo = parseInt(buttonElement.dataset.value)

    forms[refId].querySelectorAll('.customAmunt').forEach((element) => {
        element.value = ''
    })

    donationAmountInfo(refId)
}

donationAmountInfo = (refId) => {
    let donation = null

    console.log(steps[refId][currentStep[refId]])
    if (steps[refId][currentStep[refId]] === undefined) return
    if (steps[refId][currentStep[refId]].length == 0) return
    if (steps[refId][currentStep[refId]][0].showDescriptionInButton) return

    steps[refId][currentStep[refId]].forEach((element) => {
        if (!!element.donation) donation = element.donation
    })

    var amount_description = false
    donation.amount.forEach((element) => {
        if (
            element.value == datas[refId].donation.importo &&
            element.type.indexOf(parseInt(datas[refId].donation.recurrent)) > -1
        )
            amount_description = element.description
    })

    /*if (!amount_description && !!datas[refId].donation.recurrent) {
        amount_description = 'Scegli un altro importo di donazione regolare'
    } else if (!amount_description && !datas[refId].donation.recurrent) {
        amount_description = 'Scegli un altro importo'
    }*/

    if (!!amount_description) {
        datas[refId].donation._description = amount_description
        forms[refId].querySelectorAll('.donationAmountInfo')[0].innerHTML = amount_description
        forms[refId].querySelectorAll('.donationAmountInfo')[0].style.display = 'flex'
    } else {
        datas[refId].donation._description = null
        forms[refId].querySelectorAll('.donationAmountInfo')[0].innerHTML = ''
        forms[refId].querySelectorAll('.donationAmountInfo')[0].style.display = 'none'
    }
}

changeCustomAmount = (event) => {
    let element = event.target
    let refId = element.closest('.aism-uniform').id

    if (parseInt(element.value) <= 0 && !element.required) return initAmount(refId, true)

    forms[refId]
        .querySelectorAll('.donationAmount')[0]
        .querySelectorAll('.aismbtn-active')
        .forEach((element2) => {
            element2.classList.remove('aismbtn-active')
        })

    element.value = parseInt(element.value)
    datas[refId].donation.importo = parseInt(element.value)

    if (
        (element.value === false || element.value == '' || element.value == null) &&
        !!element.required
    ) {
        element.classList.add('aismis-invalid')
        element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
            element2.innerHTML = 'Il campo è obbligatorio'
        })
    } else if (!!element.value && element.value % 1 != 0) {
        element.classList.add('aismis-invalid')
        element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
            element2.innerHTML = "L'importo deve essere intero"
        })
    } else if (!!element.value && parseInt(element.value) < parseInt(element.min)) {
        element.classList.add('aismis-invalid')
        element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
            element2.innerHTML = "L'importo minimo è " + parseInt(element.min) + '€'
        })
    } else if (!!element.value && parseInt(element.value) > parseInt(element.max)) {
        element.classList.add('aismis-invalid')
        element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
            element2.innerHTML = "L'importo massimo è " + parseInt(element.max) + '€'
        })
    } else {
        element.classList.remove('aismis-invalid')
    }

    donationAmountInfo(refId)
}

customAmountButton = (event) => {
    let element = event.target
    let refId = element.closest('.aism-uniform').id
    let donation = null

    steps[refId][currentStep[refId]].forEach((element) => {
        if (!!element.donation) donation = element.donation
    })

    if (!!donation && !!donation.amount) {
        donation.amount.forEach((element2) => {
            if (
                element2.type.indexOf(parseInt(datas[refId].donation.recurrent)) == -1 ||
                element2.value != datas[refId].donation.importo
            )
                return

            forms[refId]
                .querySelectorAll('.donationAmount')[0]
                .querySelectorAll('[data-value="' + element2.value + '"]')
                .forEach((element3) => {
                    element3.classList.add('aismbtn-active')
                })

            element.value = ''
        })
    }
}

init = (refId) => {
    if (!!isUnsupported()) {
        var stepData = steps[refId][0][0]

        let html = ''

        html += '<div class="aism-loader"><span class="step-loader"></span></div>'

        html += '<div class="step-content">'

        let text = !!stepData.unsupportedBrowserText
            ? stepData.unsupportedBrowserText
            : 'Siamo spiacenti, ma il tuo browser non è supportato! Ti preghiamo di aggiornarlo o provare con un altro browser.'

        html += '<p class="aism-browserunsupported">' + text + '</p>'

        html += '</div>'

        forms[refId].innerHTML = html

        hideLoader(refId)
    } else {
        datas[refId].donation = datas[refId].donation ?? {}
        datas[refId].payment = datas[refId].payment ?? {}
        datas[refId].form = datas[refId].form ?? {}

        steps[refId].forEach((elementStep) => {
            elementStep.forEach((element) => {
                if (!!element.donation) {
                    datas[refId].donation.hasMadeDonation =
                        datas[refId].donation.hasMadeDonation ?? false

                    if (!!element.donation.type) {
                        datas[refId].donation.recurrent = datas[refId].donation.recurrent ?? null

                        var defaultEl = element.donation.type.find((element2) => !!element2.default)

                        if (!!donorInfo[refId] && Object.keys(donorInfo[refId]).length > 0)
                            var defaultElUrl = element.donation.type.find(
                                (element2) => element2.value == donorInfo[refId].frequenza
                            )
                        else
                            var defaultElUrl =
                                typeof urlParams.recurrent !== 'undefined'
                                    ? element.donation.type.find(
                                        (element2) => element2.value == urlParams.recurrent
                                    )
                                    : undefined

                        console.log('default', defaultEl, defaultElUrl)
                        datas[refId].donation.recurrent =
                            datas[refId].donation.recurrent ??
                            (defaultElUrl !== undefined
                                ? defaultElUrl.value
                                : defaultEl !== undefined
                                    ? defaultEl.value
                                    : null)
                    }

                    if (!!element.donation.amount) {
                        if (!datas[refId].donation.importo) {
                            if (!!urlParams.amount) datas[refId].donation.importo = urlParams.amount
                            else datas[refId].donation.importo = null
                        }

                        if (
                            datas[refId].donation.recurrent != null &&
                            !datas[refId].donation.importo
                        ) {
                            let nextDefault = false

                            element.donation.amount.forEach((element2) => {
                                if (
                                    !!donorInfo[refId] &&
                                    donorInfo[refId].importo >= element2.value
                                ) {
                                    nextDefault = !!element2.default
                                    return
                                }

                                if (
                                    element2.type.indexOf(datas[refId].donation.recurrent) == -1 ||
                                    (!element2.default && !nextDefault)
                                )
                                    return
                                else {
                                    nextDefault = false
                                    datas[refId].donation.importo = element2.value
                                }
                            })
                        }
                    }
                }

                if (!!element.form) {
                    element.form.forEach((element2) => {
                        if (
                            !!datas[refId].form[element2.name] ||
                            datas[refId].form[element2.name] == 0
                        )
                            return

                        if (element2.type == 'checkbox') {
                            datas[refId].form[element2.name] = []

                            if (element2.value instanceof Array)
                                datas[refId].form[element2.name] = element2.value
                            else if (!!element2.value || element2.value == 0)
                                datas[refId].form[element2.name].push(element2.value)
                        } else {
                            if (!!element2.value || element2.value == 0)
                                datas[refId].form[element2.name] = element2.value
                            else datas[refId].form[element2.name] = null
                        }
                    })
                }

                if (!!element.form_custom) {
                    datas[refId]['custom_' + element.id] =
                        datas[refId]['custom_' + element.id] ?? {}

                    element.form_custom.forEach((element2) => {
                        if (
                            !!datas[refId]['custom_' + element.id][element2.name] ||
                            datas[refId]['custom_' + element.id][element2.name] == 0
                        )
                            return

                        if (
                            typeof datas[refId].custom !== 'undefined' &&
                            typeof datas[refId].custom[element2.name] !== 'undefined' &&
                            (!!datas[refId].custom[element2.name] ||
                                datas[refId].custom[element2.name] == 0)
                        ) {
                            datas[refId]['custom_' + element.id][element2.name] =
                                datas[refId].custom[element2.name]

                            delete datas[refId].custom[element2.name]

                            return
                        }

                        if (element2.type == 'checkbox') {
                            datas[refId]['custom_' + element.id][element2.name] = []

                            if (element2.value instanceof Array)
                                datas[refId]['custom_' + element.id][element2.name] = element2.value
                            else if (!!element2.value || element2.value == 0)
                                datas[refId]['custom_' + element.id][element2.name].push(
                                    element2.value
                                )
                        } else {
                            if (!!element2.value || element2.value == 0)
                                datas[refId]['custom_' + element.id][element2.name] = element2.value
                            else datas[refId]['custom_' + element.id][element2.name] = null
                        }
                    })
                }
            })
        })

        initStep(refId)
    }
}

showLoader = (refId) => {
    forms[refId]?.querySelectorAll('.aism-loader').forEach((element) => {
        element.classList.add('show')
    })
}

hideLoader = (refId) => {
    forms[refId]?.querySelectorAll('.aism-loader').forEach((element) => {
        element.classList.remove('show')
    })
}

isUnsupported = () => {
    let ua = window.navigator.userAgent
    let msie = ua.indexOf('MSIE ')

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)))
        return true
    }

    return false
}

hasMadePayment = (event) => {
    let refId = event.target.closest('.aism-uniform').id

    datas[refId].donation.hasMadeDonation = true

    nextStep(event)
}

hasNotMadePayment = (event) => {
    let refId = event.target.closest('.aism-uniform').id

    datas[refId].donation.hasMadeDonation = false

    nextStep(event)
}

donationInfoText = (refId, stepData) => {
    let donationStepIndex = null
    let donationStep = null
    steps[refId].forEach((element, elementKey) => {
        element.forEach((element2) => {
            if (!!element2.donation) {
                donationStepIndex = elementKey
                donationStep = element2
            }
        })
    })
    console.log('donationStep', donationStep)
    let html = ''
    let donationTypeString = ''
    let donationAmountString = ''
    if (
        !!datas[refId].donation &&
        !!datas[refId].donation.importo &&
        donationStepIndex !== null &&
        currentStep[refId] > donationStepIndex
    ) {
        if (datas[refId].donation.recurrent == '2') donationTypeString = 'annuale'
        else if (datas[refId].donation.recurrent == '1') donationTypeString = 'mensile'
        else donationTypeString = 'singola'
        // SE Modifico type devo cambiarlo ovunque, da rivedere
        let type = !stepData.formDonazioni ? 'Iscrizione' : 'Donazione'
        if (!stepData.formDonazioni) {
            if (!!donationStep) {
                let donationTypes = donationStep.donation.type
                let donationType = donationTypes.find((element) => element.value == datas[refId].donation.recurrent)
                donationTypeString = donationType.label
                if (datas[refId].donation.recurrent == '2') donationAmountString = datas[refId].donation.importo + ' €/anno'
                else if (datas[refId].donation.recurrent == '1') donationAmountString = datas[refId].donation.importo + ' €/mese'
                else donationAmountString = datas[refId].donation.importo + ' €'
            } else {
                if (datas[refId].donation.recurrent == '2') donationTypeString = 'annuale'
                else if (datas[refId].donation.recurrent == '1') donationTypeString = 'mensile'
                else donationTypeString = 'singola'
            }
        }
        html += '<div class="aism-donationdetails">'
        html +=
            '<span class="aism-donationdetails-title">' +
            type +
            ' ' +
            donationTypeString +
            ' di <span class="aism-color-orange">' +
            donationAmountString +
            '</span></span>'
        html += !!datas[refId].donation._description
            ? '<span class="aism-donationdetails-body">' +
            datas[refId].donation._description +
            '</span>'
            : ''
        html += '</div>'
    }
    return html
}

copyCurrentURLToClipboard = (event) => {
    navigator.clipboard.writeText(window.location.href).then(() => {
        alert("L'indirizzo è stato copiato correttamente!")

        hasMadePayment(event)
    })
}

initStep = (refId) => {
    let html = ''
    let showBackBtn = false
    let showGoBtn = true
    let secureText = false
    let footerText = false
    let redirectUrl = false
    let switchPayment = false

    if (!!datas[refId].base.sandbox) html += '<span class="sandbox-label">Test</span>'

    html += '<div class="aism-loader show"><span class="step-loader"></span></div>'

    forms[refId].innerHTML = html

    html += '<div id="s' + datas[refId].base.source + '" class="step-content">'

    if (!!steps[refId][currentStep[refId]]) {
        steps[refId][currentStep[refId]].forEach((stepData, key) => {
            if (key == 0) {
                html += donationInfoText(refId, stepData)
            }

            if (!!stepData.title) html += '<span class="step-title">' + stepData.title + '</span>'

            if (!!stepData.subTitle)
                html += '<span class="step-subtitle">' + stepData.subTitle + '</span>'

            if (!!datas[refId].base.errorMessage) {
                html += '<div class="aism-errormessage">'
                html += '<span class="aism-errormessage-image"></span>'

                switch (datas[refId].base.errorMessage?.toUpperCase()) {
                    default:
                        if (datas[refId].base.customErrorMessage)
                            html +=
                                '<span class="aism-errormessage-body">' +
                                datas[refId].base.errorMessage +
                                '</span>'
                        else
                            html +=
                                '<span class="aism-errormessage-body">Si è verificato un errore durante il processo di donazione. Si prega di riprovare.</span>'
                        break
                }

                html +=
                    '<button type="button" class="aismbtn aism-errormessage-close">&times;</button>'
                html += '</div>'
            }

            if (!!stepData.donation && !!stepData.donation.type) {
                var hasRecurrent = false
                var hasAnnual = false
                var hasMonthly = false

                if (!!stepData.donation.type.length && stepData.donation.type.length > 1) {
                    html += '<div class="aismbtn-group">'
                }

                stepData.donation.type.forEach((element) => {
                    var selected =
                        element.value == datas[refId].donation.recurrent ? 'aismbtn-active' : ''

                    if (!!stepData.donation.type.length && stepData.donation.type.length > 1)
                        html +=
                            '<button type="button" class="aismbtn aismbtn-switch ' +
                            selected +
                            '" data-value="' +
                            element.value +
                            '">' +
                            element.label +
                            '</button>'

                    if (element.value == 1 || element.value == 2) hasRecurrent = true
                    if (element.value == 1) hasMonthly = true
                    if (element.value == 2) hasAnnual = true
                })

                if (!!stepData.donation.type.length && stepData.donation.type.length > 1)
                    html += '</div>'

                if (typeof datas[refId].donation.recurrentMessage === 'undefined') {
                    datas[refId].donation.recurrentMessage = !!hasRecurrent && !!stepData.recurrentMessageText
                }

                if (!!datas[refId].donation.recurrentMessage) {
                    html += '<div class="aism-recurrentdonation">'
                    html += '<span class="aism-recurrentdonation-image"></span>'

                    html +=
                        '<span class="aism-recurrentdonation-body"><b>' + stepData.recurrentMessageText + '</span>'
                    html +=
                        '<button type="button" class="aismbtn aism-recurrentdonation-close">&times;</button>'
                    html += '</div>'
                }
            }

            if (
                !!stepData.donation &&
                !!stepData.donation.amount &&
                datas[refId].donation.recurrent != null
            ) {
                html += '<div class="donationAmount aismbtn-group"></div>'

                if (!!stepData.donation.customAmount.allow) {
                    html += '<div class="aismname-customamount-separator">oppure...</div>'
                    html += '<div class="aismform-group aismname-customamount"></div>'
                }

                if (!stepData.showDescriptionInButton) {
                    html += '<div class="donationAmountInfo"></div>'
                }
            }

            if (!!stepData.switch_payment) {
                switchPayment = true

                html += '<div class="aismswitchpayment">'
                html +=
                    '<button type="button" class="aismbtn aismbtn-yespayment">Si, l\'ho già effettuato</button>'
                html +=
                    '<button type="button" class="aismbtn aismbtn-nopayment">No, voglio effettuarlo</button>'
                html += '</div>'
            }

            if (!!stepData.form || !!stepData.form_custom) {
                let data_form = null
                let step_form = null
                let dataFormRef = ''
                let defaultData = getDefaultsFormData() || {}

                if (!!stepData.form) {
                    for (const property in defaultData) {
                        let el = stepData.form.find((element) => element.name == property)
                        if (el)
                            datas[refId].form[property] =
                                datas[refId].form[property] ?? defaultData[property]
                    }

                    data_form = datas[refId].form
                    step_form = stepData.form

                    dataFormRef = ' data-form="form"'
                } else {
                    for (const property in defaultData) {
                        let el = stepData.form_custom.find((element) => element.name == property)
                        if (el)
                            datas[refId]['custom_' + stepData.id][property] =
                                datas[refId]['custom_' + stepData.id][property] ??
                                defaultData[property]
                    }

                    data_form = datas[refId]['custom_' + stepData.id]
                    step_form = stepData.form_custom

                    dataFormRef = ' data-form="custom_' + stepData.id + '"'
                }

                // html += '<form>'

                step_form.forEach((element) => {
                    if (!element.name) return

                    let required = ''
                    let typeRequired =
                        typeof datas[refId].donation.recurrent !== 'undefined'
                            ? parseInt(datas[refId].donation.recurrent)
                            : 0

                    if (
                        !!element.required &&
                        element.required.length &&
                        element.required.indexOf(typeRequired) != -1
                    ) {
                        required = ' required'
                    }

                    let label = !!element.label ? element.label : ''

                    if (!required) {
                        if (!!label) label += ' '
                        label += '<span class="aismnotrequired">(Opzionale)</span>'
                    }

                    if (!!required) {
                        if (!!label) label += ' <span class="aismrequired">(*)</span>'
                    }

                    if (!!element.sublabel) {
                        if (!!label) label += ' '
                        label += '<span class="aismlabelsub">(' + element.sublabel + ')</span>'
                    }

                    let placeholder = !!element.placeholder ? element.placeholder : ''

                    switch (element.type) {
                        case 'file':
                            if (!!stepData.form_custom) break
                            html += '<div class="aismform-group aismname-' + element.name + '">'
                            if (!!label)
                                html +=
                                    '<label class="aismLabel" for="' +
                                    element.name +
                                    '">' +
                                    label +
                                    '</label>'
                            html +=
                                '<input type="' +
                                element.type +
                                '" data-type="' +
                                element.type +
                                '" class="aismform-control" name="' +
                                element.name +
                                '" title="' +
                                element.label +
                                '" ' +
                                dataFormRef +
                                required +
                                '/>'
                            if (!!element.description)
                                html +=
                                    '<small id="' +
                                    refId +
                                    '_' +
                                    element.name +
                                    'Help" class="aismform-text">' +
                                    element.description +
                                    '</small>'
                            html +=
                                '<div class="aisminvalid-feedback">Il campo è obbligatorio</div>'
                            html += '</div>'
                            break
                        case 'checkbox':
                        case 'radio':
                            html += '<div class="aismform-group aismname-' + element.name + '">'

                            if (!!label)
                                html +=
                                    '<label class="aismLabel" for="' +
                                    element.name +
                                    '">' +
                                    label +
                                    '</label>'

                            if (!!element.options) {
                                element.options.forEach((option) => {
                                    if (element.type == 'checkbox')
                                        var checked =
                                            data_form[element.name].indexOf(
                                                option.value.toString()
                                            ) != -1
                                                ? ' checked'
                                                : ''
                                    else
                                        var checked =
                                            data_form[element.name] == option.value
                                                ? ' checked'
                                                : ''

                                    html +=
                                        '<div class="aismcustom-control aismcustom-' +
                                        element.type +
                                        '">'
                                    html +=
                                        '<input type="' +
                                        element.type +
                                        '" data-type="' +
                                        element.type +
                                        '" class="aismcustom-control-input" name="' +
                                        element.name +
                                        '" id="' +
                                        refId +
                                        '_' +
                                        element.name +
                                        '_' +
                                        option.value +
                                        '" value="' +
                                        option.value +
                                        '" ' +
                                        dataFormRef +
                                        required +
                                        checked +
                                        '/>'

                                    html +=
                                        '<label class="aismcustom-control-label" for="' +
                                        refId +
                                        '_' +
                                        element.name +
                                        '_' +
                                        option.value +
                                        '">' +
                                        option.label +
                                        '</label>'

                                    html +=
                                        '<div class="aisminvalid-feedback">Il campo è obbligatorio</div>'
                                    html += '</div>'
                                })
                            }

                            if (!!element.description)
                                html +=
                                    '<small id="' +
                                    refId +
                                    '_' +
                                    element.name +
                                    'Help" class="aismform-text">' +
                                    element.description +
                                    '</small>'

                            html += '</div>'

                            break
                        case 'select':
                            html += '<div class="aismform-group aismname-' + element.name + '">'

                            if (!!label)
                                html +=
                                    '<label class="aismLabel" for="' +
                                    element.name +
                                    '">' +
                                    label +
                                    '</label>'

                            html +=
                                '<select class="aismcustom-select" name="' +
                                element.name +
                                '" id="' +
                                refId +
                                '_' +
                                element.name +
                                '" ' +
                                dataFormRef +
                                required +
                                '>'

                            let defaultOption = !required
                                ? ' value="null"'
                                : ' value="null" selected disabled'

                            placeholder = !!placeholder ? placeholder : '- Seleziona -'

                            html += '<option ' + defaultOption + '>' + placeholder + '</option>'

                            if (!!element.options) {
                                element.options.forEach((option) => {
                                    let selected =
                                        data_form[element.name] == option.value ? ' selected' : ''

                                    html +=
                                        '<option value="' +
                                        option.value +
                                        '" ' +
                                        selected +
                                        '>' +
                                        option.label +
                                        '</option>'
                                })
                            }

                            html += '</select>'

                            if (!!element.description)
                                html +=
                                    '<small id="' +
                                    refId +
                                    '_' +
                                    element.name +
                                    'Help" class="aismform-text">' +
                                    element.description +
                                    '</small>'

                            html +=
                                '<div class="aisminvalid-feedback">Il campo è obbligatorio</div>'
                            html += '</div>'
                            break
                        case 'textarea':
                            var value = !!data_form[element.name] ? data_form[element.name] : ''
                            var maxLength = !!element.maxLength ? (' maxlength=' + element.maxLength + ' ') : ''
                            console.log('textarea', element)


                            html += '<div class="aismform-group aismname-' + element.name + '">'

                            if (!!label)
                                html +=
                                    '<label class="aismLabel" for="' +
                                    element.name +
                                    '">' +
                                    label +
                                    '</label>'

                            html +=
                                '<textarea class="aismform-control" name="' +
                                element.name +
                                '" placeholder="' +
                                placeholder +
                                '" title="' +
                                element.label +
                                '" ' +
                                maxLength +
                                dataFormRef +
                                required +
                                '>' +
                                value +
                                '</textarea>'

                            if (!!maxLength) {
                                html += '<small class="aismform-textarea-max">Max ' + element.maxLength + ' caratteri</small>'
                            }

                            if (!!element.description)
                                html +=
                                    '<small id="' +
                                    refId +
                                    '_' +
                                    element.name +
                                    'Help" class="aismform-text">' +
                                    element.description +
                                    '</small>'

                            html +=
                                '<div class="aisminvalid-feedback">Il campo è obbligatorio</div>'
                            html += '</div>'
                            break
                        default:
                            var pattern = ''

                            var value = !!data_form[element.name]
                                ? ' value="' + data_form[element.name] + '"'
                                : ''

                            if (element.type == 'tel') {
                                pattern = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s.\\0-9]*$'
                            } else if (element.type == 'email') {
                                pattern =
                                    '^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$'
                            } else if (element.type == 'date') {
                                if (!!placeholder) placeholder += ' '
                                placeholder += '(DD/MM/AAAA)'
                            } else if (element.type == 'time') {
                                if (!!placeholder) placeholder += ' '
                                placeholder += '(HH:MM)'
                            }

                            if (!!pattern) pattern = "pattern='" + pattern + "'"

                            html += '<div class="aismform-group aismname-' + element.name + '">'

                            if (!!label)
                                html +=
                                    '<label class="aismLabel" for="' +
                                    element.name +
                                    '">' +
                                    label +
                                    '</label>'

                            html +=
                                '<input type="' +
                                element.type +
                                '" data-type="' +
                                element.type +
                                '" class="aismform-control" name="' +
                                element.name +
                                '" placeholder="' +
                                placeholder +
                                '" ' +
                                pattern +
                                ' title="' +
                                element.label +
                                '" ' +
                                dataFormRef +
                                required +
                                value +
                                '/>'

                            if (!!element.description)
                                html +=
                                    '<small id="' +
                                    refId +
                                    '_' +
                                    element.name +
                                    'Help" class="aismform-text">' +
                                    element.description +
                                    '</small>'

                            html +=
                                '<div class="aisminvalid-feedback">Il campo è obbligatorio</div>'
                            html += '</div>'
                    }
                })

                // html += '</form>'
            }

            amazonPay = false

            if (!!stepData.payments) {
                const imported = document.createElement('script')
                imported.src = 'https://js.stripe.com/v3/'
                imported.async = true
                document.body.appendChild(imported)

                html += '<div class="aismbtn-payments">'
                stepData.payments.forEach((element) => {
                    if (element.donation.indexOf(parseInt(datas[refId].donation.recurrent)) == -1)
                        return
                    switch (element.type) {
                        case 'card':
                            html +=
                                '<button type="button" class="aismbtn aismbtn-stripe">' +
                                element.label +
                                '</button>'
                            break
                        case 'amazon_pay':
                        case 'amazonpay':
                            html +=
                                '<button type="button" class="aismbtn aismbtn-amazonpay"></button>'
                            break
                        case 'sepa_debit':
                            html +=
                                '<button type="button" class="aismbtn aismbtn-sepa">' +
                                element.label +
                                '</button>'
                            break
                        case 'paypal':
                            html += '<button type="button" class="aismbtn aismbtn-paypal"></button>'
                            break
                        case 'satispay':
                            html +=
                                '<button type="button" class="aismbtn aismbtn-satispay"></button>'
                            break
                    }
                })
                html += '</div>'
            }

            if (!showBackBtn && !stepData.hideBackBtn) showBackBtn = true
            if (!secureText && !!stepData.secureText) secureText = true
            if (!footerText && !!stepData.footerText) {
                footerText = stepData.footerText

                for (const index in datas[refId].form) {
                    const element = datas[refId].form[index]

                    footerText = footerText.replace(new RegExp('{' + index + '}', 'g'), element)
                }
            }

            if (!!stepData.redirectUrl) redirectUrl = stepData.redirectUrl

            if (!!stepData.buttonText && !!showGoBtn) {
                if (
                    !stepData.redirectUrl &&
                    (typeof stepData.payments === 'undefined' || !stepData.payments.length)
                ) {
                    showGoBtn = stepData.buttonText
                } else showGoBtn = false
            } else showGoBtn = false
        })
    }

    html += '</div>'

    if (currentStep[refId] <= 0) showBackBtn = false
    if (!!switchPayment) showGoBtn = false

    html += '<div class="aismbuttons">'
    if (!!showGoBtn) {
        html += '<button type="button" class="aismbtn aismbtn-go">' + showGoBtn + '</button>'
    } else if (
        !!isLastStep(refId, currentStep[refId]) &&
        (!hasStepPayment(refId) || !!datas[refId].donation.hasMadeDonation)
    ) {
        lastStepRequest(refId)
    }

    if (!!showBackBtn) {
        html += '<button type="button" class="aismbtn aismbtn-back">Torna Indietro</button>'
    }
    html += '</div>'

    if (!!secureText) {
        html += '<p class="secure-text">'
        html += '<span class="secure"></span>'
        html +=
            'Pagamenti sicuri, grazie a Stripe e PayPal: protezione dei dati a 256bit, per la massima protezione nelle transazioni online.'
        /*html +=
            'Pagamenti sicuri, grazie a Stripe, PayPal, Satispay e Amazon Pay: protezione dei dati a 256bit, per la massima protezione nelle transazioni online.'*/
        html += '</p>'

        if (payments[refId]['card']) {
            html += '<ul class="card-brands">'
            html += '<li class="card-visa">Visa</li>'
            html += '<li class="card-mastercard">MasterCard</li>'
            html += '<li class="card-amex">American Express</li>'
        }
        if (payments[refId]['paypal']) {
            html += '<li class="paypal">PayPal</li>'
        }
        if (payments[refId]['satispay']) {
            // html += '<li class="satispay">Satispay</li>'
        }
        if (payments[refId]['apple_pay']) {
            html += '<li class="applepay">Apple Pay</li>'
        }
        if (payments[refId]['google_pay']) {
            html += '<li class="googlepay">Google Pay</li>'
        }
        if (payments[refId]['sepa_debit']) {
            // html += '<li class="sepa">SEPA</li>'
        }
        if (payments[refId]['amazon_pay']) {
            // html += '<li class="amazonpay">Amazon Pay</li>'
        }
        html += '</ul>'
    }

    if (!!footerText) {
        html += '<div class="aism-stepfooter">' + footerText + '</div>'
    }

    forms[refId].innerHTML = html
    // nodeScriptReplace(document.getElementsByTagName('body')[0])
    nodeScriptReplace(forms[refId])

    setTimeout(
        function () {
            maskInit(refId)

            hideLoader(refId)

            if (!!redirectUrl) {
                setTimeout(function () {
                    window.location.href = redirectUrl
                }, 2000)
            }

            initListeners(refId)

            let messageTimer = !!isMobile ? 2000 : 3000
            setTimeout(function () {
                openRecurrentMessage(refId)
            }, messageTimer)
        },
        footerText ? 500 : 1
    )
}

maskInit = (refId) => {
    if (typeof IMask === 'undefined') return null
    forms[refId].querySelectorAll('input[data-type="date"]').forEach((element) => {
        if (!element.name || element.type == element.dataset.type) return null
        maskObjs[element.name] = IMask(element, {
            mask: Date,
            pattern: 'd{/}`m{/}`Y',
            blocks: {
                d: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31,
                    maxLength: 2,
                    placeholderChar: 'g',
                },
                m: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12,
                    maxLength: 2,
                    placeholderChar: 'm',
                },
                Y: {
                    mask: IMask.MaskedRange,
                    from: 1970,
                    to: 9999,
                    maxLength: 4,
                    placeholderChar: 'a',
                },
            },
            overwrite: true,
            lazy: false,
            autofix: true,
            format: function (date) {
                var day = date.getDate()
                var month = date.getMonth() + 1
                var year = date.getFullYear()

                if (day < 10) day = '0' + day
                if (month < 10) month = '0' + month

                return [day, month, year].join('/')
            },
            parse: function (str) {
                var yearMonthDay = str.split('/')

                return new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0])
            },
        })
    })

    forms[refId].querySelectorAll('input[data-type="time"]').forEach((element) => {
        if (!element.name || element.type == element.dataset.type) return null

        maskObjs[element.name] = IMask(element, {
            mask: 'H{:}`i',
            placeholderChar: '-',
            lazy: false,
            autofix: true,
            overwrite: true,
            blocks: {
                H: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 23,
                    maxLength: 2,
                },
                i: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 59,
                    maxLength: 2,
                },
            },
        })
    })

    forms[refId].querySelectorAll('input[data-type="tel"]').forEach((element) => {
        if (!element.name) return null

        maskObjs[element.name] = IMask(element, {
            mask: '#%%%[%%%%%%%%%%%%%%%%%%]',
            autofix: true,
            definitions: {
                '#': /[0-9+]/,
                '%': /[0-9- ()]/,
            },
        })
    })
}

isMobile = () => {
    if (typeof window.screen === 'undefined' || typeof window.screen.width === 'undefined')
        return null

    if (window.screen.width <= 992) return true

    return false
}

initListeners = (refId) => {
    forms[refId].querySelectorAll('.aismbtn-back').forEach((element) => {
        element.addEventListener('click', previousStep)
    })

    forms[refId].querySelectorAll('.aismbtn-go').forEach((element) => {
        element.addEventListener('click', nextStep)
    })

    forms[refId].querySelectorAll('.aismbtn-yespayment').forEach((element) => {
        element.addEventListener('click', hasMadePayment)
    })

    forms[refId].querySelectorAll('.aismbtn-nopayment').forEach((element) => {
        element.addEventListener('click', hasNotMadePayment)
    })

    forms[refId].querySelectorAll('.aismbtn-switch').forEach((element) => {
        element.addEventListener('click', changeRecurrent)
    })

    initCustomAmount(refId)
    initAmount(refId)

    forms[refId].querySelectorAll('input:not(.customAmunt),select,textarea').forEach((element) => {
        if (!element.name) return false

        element.addEventListener('input', updateElementValue)
    })

    forms[refId].querySelectorAll('.aismbtn-stripe').forEach((element) => {
        element.addEventListener('click', stripeCheckout)
    })

    forms[refId].querySelectorAll('.aismbtn-sepa').forEach((element) => {
        element.addEventListener('click', sepaCheckout)
    })

    forms[refId].querySelectorAll('#pay-sepa').forEach((element) => {
        element.addEventListener('click', sepaSubmit)
    })

    forms[refId].querySelectorAll('.aismbtn-paypal').forEach((element) => {
        element.addEventListener('click', paypalCheckout)
    })

    forms[refId].querySelectorAll('.aismbtn-satispay').forEach((element) => {
        element.addEventListener('click', satispayCheckout)
    })

    forms[refId].querySelectorAll('.aismbtn-amazonpay').forEach((element) => {
        element.addEventListener('click', amazonpayCheckout)
    })

    forms[refId].querySelectorAll('.aism-recurrentdonation-close').forEach((element) => {
        element.addEventListener('click', closeRecurrentMessage)
    })

    forms[refId].querySelectorAll('.aism-errormessage-close').forEach((element) => {
        element.addEventListener('click', closeErrorMessage)
    })

    forms[refId].querySelectorAll('.copyCurrentURLToClipboard').forEach((element) => {
        element.addEventListener('click', copyCurrentURLToClipboard)
    })
}

arrayReplaceVars = (options, params) => {
    let output = options
    let re = /{([^}]+)}/g

    Object.keys(options).forEach((item, index, arr) => {
        let text = re.exec(options[item])

        if (!!text && !!text.length && typeof params[text[1]] !== 'undefined') {
            output[item] = output[item].replace(text[0], params[text[1]])
        }
    })

    return output
}

gtmPush = (options, params) => {
    let output = arrayReplaceVars(options, params)

    if (typeof dataLayer !== 'undefined') dataLayer.push(output)
}

validateStep = (refId) => {
    var stepData = steps[refId][currentStep[refId]]
    var valid = true

    forms[refId].querySelectorAll('input, select, textarea').forEach((element) => {
        var selectorName = false
        var validEl = true
        let elementValue = element.value
        let refForm = 'form'

        if (!!element.dataset.form) refForm = element.dataset.form

        if (
            !!element.name &&
            typeof maskObjs[element.name] !== 'undefined' &&
            maskObjs[element.name].typedValue != null &&
            maskObjs[element.name].typedValue !== ''
        ) {
            datas[refId][refForm][element.name] = maskObjs[element.name].typedValue
            elementValue = maskObjs[element.name].typedValue
        }

        if (element.classList.contains('customAmunt')) {
            if (
                (elementValue === false || elementValue == '' || elementValue == null) &&
                !!element.required
            ) {
                validEl = false

                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = 'Il campo è obbligatorio'
                })
            } else if (!!elementValue && elementValue % 1 != 0) {
                validEl = false

                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = "L'importo deve essere intero"
                })
            } else if (!!elementValue && parseInt(elementValue) < parseInt(element.min)) {
                validEl = false

                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = "L'importo minimo è " + parseInt(element.min) + '€'
                })
            } else if (!!elementValue && parseInt(elementValue) > parseInt(element.max)) {
                validEl = false

                element.parentNode.querySelectorAll('.aisminvalid-feedback').forEach((element2) => {
                    element2.innerHTML = "L'importo massimo è " + parseInt(element.max) + '€'
                })
            }
        } else {
            switch (element.tagName.toUpperCase()) {
                case 'INPUT':
                    if (!!element.dataset.type)
                        selectorName =
                            element.tagName.toUpperCase() + '.' + element.dataset.type.toUpperCase()
                    else
                        selectorName =
                            element.tagName.toUpperCase() + '.' + element.type.toUpperCase()
                    break
                default:
                    selectorName = element.tagName.toUpperCase()
            }

            switch (selectorName) {
                case 'SELECT':
                    if (
                        (elementValue == 'null' ||
                            elementValue == null ||
                            elementValue == '' ||
                            typeof elementValue === 'undefined') &&
                        !!element.required
                    ) {
                        validEl = false
                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il campo è obbligatorio'
                            })
                        break
                    }
                    break
                case 'INPUT.FILE':
                    /* if (datas[refId][refForm][element.name] != elementValue)
                        datas[refId][refForm][element.name] = elementValue */
                    if (!elementValue && !!element.required) {
                        validEl = false
                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il campo è obbligatorio'
                            })
                        break
                    }
                    break
                case 'INPUT.NUMBER':
                    if (datas[refId][refForm][element.name] != elementValue)
                        datas[refId][refForm][element.name] = elementValue

                    if (
                        (elementValue === false ||
                            elementValue == '' ||
                            elementValue == null ||
                            elementValue.replace(/\s+/g, '') == '') &&
                        !!element.required
                    ) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il campo è obbligatorio'
                            })

                        break
                    }

                    if (
                        (!!elementValue || elementValue === 0) &&
                        element.step % 1 == 0 &&
                        elementValue % 1 != 0
                    ) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il valore deve essere intero'
                            })

                        break
                    }

                    if (
                        (!!elementValue || elementValue === 0) &&
                        !!element.min &&
                        parseInt(element.min) > parseInt(elementValue)
                    ) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il valore minimo è ' + parseInt(element.min)
                            })

                        break
                    }

                    if (
                        (!!elementValue || elementValue === 0) &&
                        !!element.max &&
                        parseInt(element.max) < parseInt(elementValue)
                    ) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il valore massimo è ' + parseInt(element.max)
                            })

                        break
                    }

                    break
                case 'INPUT.EMAIL':
                    if (datas[refId][refForm][element.name] != elementValue)
                        datas[refId][refForm][element.name] = elementValue

                    if (
                        (!elementValue || elementValue.replace(/\s+/g, '') == '') &&
                        !!element.required
                    ) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il campo è obbligatorio'
                            })

                        break
                    }

                    if (!validateEmail(elementValue) && !!elementValue) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il testo inserito non è un indirizzo email'
                            })

                        break
                    }

                    break
                case 'INPUT.TEL':
                    if (
                        (!elementValue || elementValue.replace(/\s+/g, '') == '') &&
                        !!element.required
                    ) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il campo è obbligatorio'
                            })

                        break
                    }

                    if (!validatePhoneNumber(elementValue) && !!elementValue) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML =
                                    'Il testo inserito non è un numero di telefono valido'
                            })

                        break
                    }

                    break
                case 'INPUT.URL':
                    if (datas[refId][refForm][element.name] != elementValue)
                        datas[refId][refForm][element.name] = elementValue

                    if (
                        (!elementValue || elementValue.replace(/\s+/g, '') == '') &&
                        !!element.required
                    ) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il campo è obbligatorio'
                            })

                        break
                    }

                    if (!validateUrl(elementValue) && !!elementValue) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il testo inserito non è uno URL valido'
                            })

                        break
                    }

                    break
                case 'INPUT.DATE':
                    elementValue = convertDate(elementValue)

                    if (
                        (!elementValue || elementValue.replace(/\s+/g, '') == '') &&
                        !!element.required
                    ) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il campo è obbligatorio'
                            })

                        break
                    }

                    if (!validateDate(elementValue) && !!elementValue) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML =
                                    'Il testo inserito non è una data valida (DD/MM/AAAA)'
                            })

                        break
                    }

                    if (
                        element.name.toUpperCase().indexOf('NASCITA') != -1 &&
                        !validateDate18Years(elementValue) &&
                        !!elementValue
                    ) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML =
                                    'La data di nascita non è di una persona maggiorenne'
                            })

                        break
                    }

                    break
                case 'INPUT.TIME':
                    if (
                        (!elementValue || elementValue.replace(/\s+/g, '') == '') &&
                        !!element.required
                    ) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il campo è obbligatorio'
                            })

                        break
                    }

                    if (!validateTime(elementValue) && !!elementValue) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML =
                                    'Il testo inserito non è un orario valido (HH:MM)'
                            })

                        break
                    }

                    break
                case 'INPUT.CHECKBOX':
                case 'INPUT.RADIO':
                    if (!!element.required) {
                        let checkedElInner = false

                        forms[refId]
                            .querySelectorAll('[name="' + element.name + '"]')
                            .forEach((element2) => {
                                if (!!element2.checked) {
                                    checkedElInner = true
                                }
                            })

                        if (!checkedElInner) validEl = false
                    }
                    break
                default:
                    if (datas[refId][refForm][element.name] != elementValue)
                        datas[refId][refForm][element.name] = elementValue

                    if (
                        datas[refId][refForm][element.name] === 'null' ||
                        datas[refId][refForm][element.name] === 'NULL'
                    )
                        datas[refId][refForm][element.name] = null

                    if (
                        (!elementValue || elementValue.replace(/\s+/g, '') == '') &&
                        !!element.required
                    ) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il campo è obbligatorio'
                            })

                        break
                    }

                    if (
                        !!element.pattern &&
                        !validatePattern(element.pattern, elementValue) &&
                        !!elementValue
                    ) {
                        validEl = false

                        element.parentNode
                            .querySelectorAll('.aisminvalid-feedback')
                            .forEach((element2) => {
                                element2.innerHTML = 'Il testo inserito non è un formato valido'
                            })

                        break
                    }
            }

            if (!!stepData) {
                let gtmFormError = null

                steps[refId][currentStep[refId]].forEach((element) => {
                    if (!!element.gtmFormError) gtmFormError = element.gtmFormError
                })

                if (!!element.name && !validEl && !!gtmFormError) {
                    gtmPush(JSON.parse(gtmFormError.replace(/'/g, '"')), {
                        fieldName: element.name,
                    })
                }
            }
        }

        if (!!valid && !validEl) valid = false

        if (!validEl) element.classList.add('aismis-invalid')
        else element.classList.remove('aismis-invalid')
    })

    return valid
}

isLastStep = (refId, step) => {
    if (step >= steps[refId].length - 1) return true
    else return false
}

closeRecurrentMessage = (event) => {
    let refId = event.target.closest('.aism-uniform').id

    forms[refId].querySelectorAll('.aism-recurrentdonation').forEach((element) => {
        element.style.display = 'none'
        datas[refId].donation.recurrentMessage = false
    })
}

closeErrorMessage = (event) => {
    let refId = event.target.closest('.aism-uniform').id

    forms[refId].querySelectorAll('.aism-errormessage').forEach((element) => {
        element.style.display = 'none'
        datas[refId].base.errorMessage = ''
        datas[refId].base.customErrorMessage = false
    })
}

openRecurrentMessage = (refId) => {
    forms[refId].querySelectorAll('.aism-recurrentdonation').forEach((element) => {
        element.style.display = 'block'
        datas[refId].donation.recurrentMessage = false
    })
}

hasEvent = (refId, payment_type = null) => {
    steps[refId][currentStep[refId]].forEach((stepData, key) => {
        let formPaymentType = stepData.form_payment_type

        switch (stepData?.event_type) {
            case 'inizio_checkout':
                startCheckin(refId, formPaymentType)
                break
            case 'info_spedizione':
                infoShipping(refId, formPaymentType)
                break
            case 'info_pagamento':
                infoPay(refId, formPaymentType, payment_type)
                break
        }
    })
}

eventComplete = (refId, el) => {
    let stepEl = ''
    let formPaymentType = ''
    if (!!steps[refId]) {
        steps[refId][currentStep[refId]]?.forEach((stepData, key) => {
            formPaymentType = stepData.form_payment_type
            return (stepEl = stepData)
        })
    } else if (el) {
        return (stepEl = el)
    }

    switch (stepEl.event_type) {
        case 'form_completato':
            return completedForm(refId, stepEl)
        case 'donazione_completata':
            return eventGoodEnd(refId)
        case 'iscrizione_newsletter_completata':
            return newsLetter(refId)
    }
}

donationType = (refId, formPaymentType) => {
    const donation = datas[refId]?.donation?.recurrent
    let type
    if (formPaymentType == 'iscrizione') return (type = 'quota_socio')

    switch (donation) {
        case 0:
            return (type = 'singola')
        case 1:
            return (type = 'mensile')
        case 2:
            return (type = 'annuale')
    }

    return type
}
donationTypeFromUrl = (formPaymentType) => {
    const donation = urlParams?.recurrent
    let type
    if (formPaymentType == 'iscrizione') return (type = 'quota_socio')

    switch (donation) {
        case '0':
            return (type = 'singola')
        case '1':
            return (type = 'mensile')
        case '2':
            return (type = 'annuale')
    }
    return type
}

formName = (refId, el) => {
    let name

    if (!!steps[refId]) {
        steps[refId][currentStep[refId]].forEach((stepData, key) => {
            return (name = stepData.formName)
        })
    } else {
        return (name = el.formName)
    }
    return name || ''
}

startCheckin = (refId, formPaymentType) => {
    const nomeHost = window.location.hostname
    const affiliation = urlParams.affiliation
    const price = datas[refId]?.donation?.importo
    const recurrent = datas[refId]?.donation?.recurrent

    window.dataLayer = window.dataLayer || []
    const event = {
        event: 'checkout',
        ecommerce: {
            affiliation: nomeHost || affiliation,
            donation_type: donationType(refId, formPaymentType), //singola - 0/mensile - 1/annuale - 2/quota_socio
            checkout: {
                actionField: { step: 1, option: 'begin_checkout' },
                products: [
                    {
                        name: recurrent > 0 ? 'Donazione regolare' : 'Donazione oneoff',
                        id: recurrent > 0 ? 'donaz_regolare' : 'donaz_oneoff',
                        price: price || '',
                        category: 'Donazione',
                        quantity: 1,
                    },
                ],
            },
        },
    }
    // console.log(event)
    window.dataLayer.push(event)
}

infoShipping = (refId, formPaymentType) => {
    const nomeHost = window.location.hostname
    const affiliation = urlParams.affiliation
    const price = datas[refId]?.donation?.importo
    const recurrent = datas[refId]?.donation?.recurrent

    window.dataLayer = window.dataLayer || []
    const event = {
        event: 'checkout',
        ecommerce: {
            affiliation: nomeHost || affiliation,
            donation_type: donationType(refId, formPaymentType),
            checkout: {
                actionField: { step: 2, option: 'add_shipping_info' },
                products: [
                    {
                        name: recurrent > 0 ? 'Donazione regolare' : 'Donazione oneoff',
                        id: recurrent > 0 ? 'donaz_regolare' : 'donaz_oneoff',
                        price: price || '',
                        category: 'Donazione',
                        quantity: 1,
                    },
                ],
            },
        },
    }
    // console.log(event)
    window.dataLayer.push(event)
}

infoPay = (refId, formPaymentType, payment_type) => {
    const nomeHost = window.location.hostname
    const affiliation = urlParams.affiliation
    const price = datas[refId]?.donation?.importo
    const recurrent = datas[refId]?.donation?.recurrent

    window.dataLayer = window.dataLayer || []
    const event = {
        event: 'checkout',
        ecommerce: {
            affiliation: nomeHost || affiliation,
            donation_type: donationType(refId, formPaymentType),
            checkout: {
                actionField: { step: 3, option: payment_type },
                products: [
                    {
                        name: recurrent > 0 ? 'Donazione regolare' : 'Donazione oneoff',
                        id: recurrent > 0 ? 'donaz_regolare' : 'donaz_oneoff',
                        price: price || '',
                        category: 'Donazione',
                        quantity: 1,
                    },
                ],
            },
        },
    }
    // console.log(event)
    window.dataLayer.push(event)
}

eventGoodEnd = (refId) => {
    const actionFieldId = urlParams.id
    const affiliation = urlParams.affiliation
    const nomeHost = window.location.hostname
    const price = urlParams.amount
    const recurrent = urlParams.recurrent
    const formPaymentType = urlParams.formPaymentType

    window.dataLayer = window.dataLayer || []
    const event = {
        event: 'purchase',
        ecommerce: {
            currencyCode: 'EUR',
            purchase: {
                actionField: {
                    id: actionFieldId,
                    affiliation: affiliation || nomeHost,
                    donation_type: donationTypeFromUrl(formPaymentType),
                    revenue: '',
                    userLTV: '',
                    idUtente: '',
                },
                products: [
                    {
                        name: recurrent > 0 ? 'Donazione regolare' : 'Donazione oneoff',
                        id: recurrent > 0 ? 'donaz_regolare' : 'donaz_oneoff',
                        price: price || '',
                        category: 'Donazione',
                        quantity: 1,
                    },
                ],
            },
        },
    }
    // console.log(event)
    window.dataLayer.push(event)
}

newsLetter = (refId, el) => {
    const nomeHost = window.location.hostname
    const affiliation = urlParams.affiliation

    window.dataLayer = window.dataLayer || []
    const event = {
        event: 'submit_newsletter_form',
        site: nomeHost || affiliation,
        checkbox: 'no',
        formName: formName(refId, el) || '',
    }
    // console.log(event)
    window.dataLayer.push(event)
}

completedForm = (refId, el) => {
    const affiliation = urlParams.affiliation
    const nomeHost = window.location.hostname

    window.dataLayer = window.dataLayer || []
    const event = {
        event: 'submit_contact_form',
        site: nomeHost || affiliation,
        formName: formName(refId, el) || '',
    }
    // console.log(event)
    window.dataLayer.push(event)
}

hasSaveData = (refId) => {
    let save = false

    steps[refId][currentStep[refId]].forEach((element) => {
        if (!!element.saveData) save = true
    })

    return save
}

hasStepPayment = (refId, step = undefined) => {
    let has = false

    if (step) {
        steps[refId][step].forEach((element) => {
            if (!!element.payments) has = true
        })
    } else {
        steps[refId].forEach((element) => {
            element.forEach((element2) => {
                if (!!element2.payments) has = true
            })
        })
    }

    return has
}

getStepPaymentIndex = (refId) => {
    let indexP = undefined

    steps[refId].forEach((element, index) => {
        element.forEach((element2) => {
            if (!!element2.payments && indexP === undefined) indexP = index
        })
    })

    return indexP
}

nextStep = (event) => {
    let ref = event.target.closest('.aism-uniform')
    let refId = ref.id

    ref.scrollIntoView()

    showLoader(refId)

    if (!validateStep(refId)) return hideLoader(refId)

    hasEvent(refId)

    if (!!hasSaveData(refId)) {
        saveData(refId, () => {
            return nextStepProcess(refId)
        })
    } else {
        return nextStepProcess(refId)
    }
}

nextStepProcess = (refId, times = 1) => {
    let next = currentStep[refId] + times

    if (!!isLastStep(refId, next - 1)) {
        if (!hasStepPayment(refId) || !!datas[refId].donation.hasMadeDonation) {
            redirectSuccessUrl(refId)
        }
    } else {
        if (!!hasStepPayment(refId, next) && !!datas[refId].donation.hasMadeDonation)
            nextStepProcess(refId, times + 1)
        else {
            currentStep[refId] = next
            initStep(refId)
        }
    }
}

previousStep = (event) => {
    let refId = event.target.closest('.aism-uniform').id
    showLoader(refId)

    if (currentStep[refId] < 1) return hideLoader(refId)

    currentStep[refId] = currentStep[refId] - 1

    if (!!datas[refId].payment && !!datas[refId].payment['client_secret'])
        delete datas[refId].payment['client_secret']
    if (!!datas[refId].payment && !!datas[refId].payment['accountholder-name'])
        delete datas[refId].payment['accountholder-name']
    if (!!datas[refId].payment && !!datas[refId].payment['accountholder-email'])
        delete datas[refId].payment['accountholder-email']
    urls = {}

    initStep(refId)
}

initStepsData = (element) => {
    forms[element.id] = element
    datas[element.id] = {
        base: {},
    }
    currentStep[element.id] = 0

    if (!!urlParams.source && (typeof urlParams.source === 'string' || !isNaN(urlParams.source)))
        datas[element.id].base.source = urlParams.source?.replace(/[^a-zA-Z0-9]/g, '')
    else if (!!element.dataset.source) datas[element.id].base.source = element.dataset.source

    if (!!urlParams.donor && (typeof urlParams.donor === 'string' || !isNaN(urlParams.donor)))
        datas[element.id].base.donor = urlParams.donor?.replace(/[^a-zA-Z0-9]/g, '')
    else datas[element.id].base.donor = null

    if (!!urlParams.cm && (typeof urlParams.cm === 'string' || !isNaN(urlParams.cm)))
        datas[element.id].base.codice_campagna = urlParams.cm?.replace(/[^a-zA-Z0-9_]/g, '')
    else datas[element.id].base.codice_campagna = null

    if (!!urlParams.az && (typeof urlParams.az === 'string' || !isNaN(urlParams.az)))
        datas[element.id].base.azione = urlParams.az?.replace(/[^a-zA-Z0-9_]/g, '')
    else datas[element.id].base.azione = null

    if (
        !!urlParams.request_id &&
        (typeof urlParams.request_id === 'string' || !isNaN(urlParams.request_id))
    )
        datas[element.id].base.request_id = urlParams.request_id
    else datas[element.id].base.request_id = null

    datas[element.id].base.sandbox = false

    if (!!getCookie('_ga')) datas[element.id].base._ga = getCookie('_ga')
    else datas[element.id].base._ga = null

    request(
        '/source/generate?source=' + (datas[element.id].base.source ?? ''),
        'GET',
        {},
        (response) => {
            if (typeof response.data === 'undefined') return

            const refId = document.getElementsByClassName('aism-uniform')[0].id

            response.data.steps.forEach((element) => {
                element.forEach((el) => {
                    if (!!el.event_type && !!urlParams.affiliation && !!urlParams.id) {
                        switch (el.event_type) {
                            case 'form_completato':
                                return completedForm(refId, el)
                            case 'donazione_completata':
                                return eventGoodEnd(refId)
                            case 'iscrizione_newsletter_completata':
                                return newsLetter(refId, el)
                        }
                    }
                })
            })

            if (!!response.data.redirect_url) {
                window.location.href = response.data.redirect_url
                return
            }

            if (!!response.data.request_id)
                datas[element.id].base.request_id = response.data.request_id
            else if (!datas[element.id].base.request_id) datas[element.id].base.request_id = null

            if (!!response.data.donor_id) datas[element.id].base.donor = response.data.donor_id
            else datas[element.id].base.donor = null

            if (!!response.data.sandbox) datas[element.id].base.sandbox = true

            if (
                !!response.data.steps &&
                Array.isArray(response.data.steps) &&
                response.data.steps.length > 0
            )
                steps[element.id] = formatSteps(response.data.steps)
            else return

            if (
                !!response.data.payments
            ) {
                payments[element.id] = response.data.payments
            }

            if (
                !!response.data.request_data &&
                Object.keys(response.data.request_data).length > 0
            ) {
                const oldData = { ...datas[element.id] }

                datas[element.id] = {
                    ...datas[element.id],
                    ...response.data.request_data,
                }
                datas[element.id].base.errorMessage = oldData.base.errorMessage
                datas[element.id].base.customErrorMessage = oldData.base.customErrorMessage

                currentStep[element.id] = getStepPaymentIndex(element.id) ?? 0
            }

            donorInfo[element.id] = response.data.donor_info

            init(element.id)

            forms[element.id].style.display = 'block'
        },
        (response) => {
            //
        }
    )
}

initStepsDataThx = (element) => {
    forms[element.id] = element
    datas[element.id] = {
        base: {},
    }

    currentStep[element.id] = 0

    if (!!urlParams.source && (typeof urlParams.source === 'string' || !isNaN(urlParams.source)))
        datas[element.id].base.source = urlParams.source?.replace(/[^a-zA-Z0-9]/g, '')
    else datas[element.id].base.source = null

    if (!!urlParams.donor && (typeof urlParams.donor === 'string' || !isNaN(urlParams.donor)))
        datas[element.id].base.donor = urlParams.donor?.replace(/[^a-zA-Z0-9]/g, '')
    else datas[element.id].base.donor = null

    if (!!urlParams.cm && (typeof urlParams.cm === 'string' || !isNaN(urlParams.cm)))
        datas[element.id].base.codice_campagna = urlParams.cm?.replace(/[^a-zA-Z0-9_]/g, '')
    else datas[element.id].base.codice_campagna = null

    if (!!urlParams.az && (typeof urlParams.az === 'string' || !isNaN(urlParams.az)))
        datas[element.id].base.azione = urlParams.az?.replace(/[^a-zA-Z0-9_]/g, '')
    else datas[element.id].base.azione = null

    if (!!urlParams.id && (typeof urlParams.id === 'string' || !isNaN(urlParams.id)))
        datas[element.id].base.request_id = urlParams.id
    else datas[element.id].base.request_id = null

    datas[element.id].base.sandbox = false

    if (!!getCookie('_ga')) datas[element.id].base._ga = getCookie('_ga')
    else datas[element.id].base._ga = null

    request(
        '/source/thxpage/generate?request_id=' + (datas[element.id].base.request_id ?? ''),
        'GET',
        {},
        (response) => {
            if (typeof response.data === 'undefined') return

            const refId = document.getElementsByClassName('aism-uniform')[0].id

            response.data.forEach((element) => {
                element.forEach((el) => {
                    if (!!el.event_type && !!urlParams.affiliation && !!urlParams.id) {
                        switch (el.event_type) {
                            case 'form_completato':
                                return completedForm(refId, el)
                            case 'donazione_completata':
                                return eventGoodEnd(refId)
                            case 'iscrizione_newsletter_completata':
                                return newsLetter(refId, el)
                        }
                    }
                })
            })

            if (!!response.data.donor_id) datas[element.id].base.donor = response.data.donor_id
            else datas[element.id].base.donor = null

            if (!!response.data.sandbox) datas[element.id].base.sandbox = true
            if (!!response.data && Array.isArray(response.data) && response.data.length > 0)
                steps[element.id] = formatSteps(response.data)
            else return

            init(element.id)

            forms[element.id].style.display = 'block'
        },
        (response) => {
            //
        }
    )
}

formatSteps = (steps) => {
    let output = []

    steps.forEach((element) => {
        let tmpArr = []

        if (!!element && element.length > 0) {
            element.forEach((element2) => {
                if (typeof element2 == 'object' && Object.keys(element2).length > 0)
                    tmpArr.push(element2)
            })
        }

        if (tmpArr.length > 0) output.push(tmpArr)
    })

    return output
}

formInit = () => {
    const importedMask = document.createElement('script')
    importedMask.src = 'https://unpkg.com/imask'
    importedMask.async = true
    document.head.appendChild(importedMask)

    initUrlParams()

    document.querySelectorAll('div.aism-uniform[data-source]').forEach((element) => {
        if (!element.dataset.source) return

        if (typeof element.id === 'undefined' || !element.id)
            element.id = 'formSync' + Math.floor(Math.random() * 10000)

        initStepsData(element)
    })

    document.querySelectorAll('div.aism-uniform[data-thx]').forEach((element) => {
        if (!element.dataset.thx) return

        if (typeof element.id === 'undefined' || !element.id)
            element.id = 'formSyncThx' + Math.floor(Math.random() * 10000)

        initStepsDataThx(element)
    })
}

window.addEventListener('DOMContentLoaded', formInit) // load | DOMContentLoaded
